const firebaseConfig = {
    apiKey: "AIzaSyCFDZdtTK1ZsatLNERYCI2U_yoXcZIXeDk",
    authDomain: "chewsr-1579815244098.firebaseapp.com",
    databaseURL: "https://chewsr-1579815244098.firebaseio.com",
    projectId: "chewsr-1579815244098",
    storageBucket: "chewsr-1579815244098.appspot.com",
    messagingSenderId: "11757405982",
    appId: "1:11757405982:web:10b8831433ba48284deb0f",
    measurementId: "G-60HWLGSL1Y"
};

export default firebaseConfig;